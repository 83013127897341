import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/scheduleTime`, payload)
        .then((response) => {
          commit('FILL_SCHEDULE_TIME', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // PEGA OS HORÁRIOS CONFORME O TIPO DE AGENDA (HORÁRIOS ESPECÍFICOS)
  fetch ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/scheduleTime/${payload}`)
        .then((response) => {
          commit('FILL_SCHEDULE_TIME', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // PEGA TODOS OS HORÁRIOS É UTILIZADO NO CADASTRO DE NOVOS HORÁRIOS SEMANAIS
  fetchAll ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/scheduleTimes`)
        .then((response) => {
          commit('FILL_SCHEDULE_TIMES', response.data) // -TODOS OS HORÁRIOS
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/scheduleTime/updateDateTime`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/scheduleTime/deleteDateTime`, payload)
        .then((response) => {
          // COMO PRECISO ACESSAR OUTRO MÓDULO, IDENTIFICO O MÓDULO CORRETO PELO TIPO DE AGENDA
          if (payload.schedule_type      === 'medical_exam')     payload.selectedDays = rootState.scheduleMedicalExam.selectedDays
          else if (payload.schedule_type === 'psychotechnical')  payload.selectedDays = rootState.schedulePsychotechnical.selectedDays
          else if (payload.schedule_type === 'theoretical_exam') payload.selectedDays = rootState.scheduleTheoreticalExam.selectedDays
          else if (payload.schedule_type === 'practical_exam')   payload.selectedDays = rootState.schedulePracticalExam.selectedDays
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // DELETA UM HORÁRIO SEMANAL (OCORRE NO MENU DE CADASTROS EM HORÁRIO DE EXAMES)
  deleteWeekdayTime ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/scheduleTime/deleteWeekdayTime`, payload)
        .then((response) => {
          commit('DELETE_WEEKDAY_TIME', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
