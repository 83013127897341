import Vue from 'vue'

export default {
  FILL_SCHEDULE_TIME (state, payload) {
    if (payload && payload.specific_days) payload.specific_days.sort((a, b) => { return new Date(`${a.slice(0, 19)}`) - new Date(`${b.slice(0, 19)}`) }) //ORDENA POR DATA
    state.scheduleTime = payload
  },
  FILL_SCHEDULE_TIMES (state, payload) { // -TODOS OS HORÁRIOS - UTILIZADO NO CADASTRO DE NOVOS HORÁRIOS SEMANAIS
    if (payload && payload.specific_days) payload.specific_days.sort((a, b) => { return new Date(`${a.slice(0, 19)}`) - new Date(`${b.slice(0, 19)}`) }) //ORDENA POR DATA
    state.scheduleTimes = payload
  },
  UPDATE_SCHEDULE_TIMES (state, payload) {
    const idx = state.scheduleTimes.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.scheduleTimes, idx, payload)
    else state.scheduleTimes.push(payload)
  },
  DELETE (state, payload) {
    const i = state.scheduleTime.specific_days.findIndex(sd => sd === `${payload.date} ${payload.start_time} ${payload.end_time}`)
    if (i >= 0) state.scheduleTime.specific_days.splice(i, 1)

    // NÃO ESQUECER DE PASSAR O selectedDays A PARTIR DO MÓDULO CORRETO NO ROOTSTATE (ver em actions DELETE)
    payload.selectedDays.map(sd => {
      const idx2 = sd.studentSchedule.findIndex(o => o.start_date === payload.date && o.start_time === payload.start_time)
      if (idx2 >= 0) sd.studentSchedule.splice(idx2, 1)
    })
  },

  // ATUA NO CADASTRO DE HORÁRIOS SEMANAS DE EXAMES
  DELETE_WEEKDAY_TIME (state, payload) {
    const idx = state.scheduleTimes.findIndex(o => o.id === payload.scheduleTime.id)
    if (idx >= 0) {
      const idx2 = state.scheduleTimes[idx].weekdays.findIndex(s => s === payload.weekdayTime)
      if (idx2 >= 0) state.scheduleTimes[idx].weekdays.splice(idx2, 1)
    }
  }
}
